@import '../../../styles/theme.scss';

#LandingPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  color: $dark-grey;
  .title {
    text-align: center;
    color: $dark-grey;
    margin: 20px auto;
    font-weight: 400;
  }
  .text-green {
    color: $green;
  }
  .ant-upload-drag {
    margin-top: 25px;
    background-color: $white;
    &:hover {
      border: 1px dashed $green;
    }
    svg {
      color: $green;
    }
  }
  .verify-document-container {
    width: 500px;
    align-self: center;
    position: relative;
  }
  .searchContainer {
    padding: 2rem;
    background: $white;
  }
  .spinner-height {
    height: 200px;
  }
  .publicDocuments {
    margin-top: 1rem;
    box-sizing: border-box;
    width: 100%;
    max-width: 900px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover,
  td.ant-table-column-sort {
    background-color: transparent;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-row {
    cursor: pointer;
    border: 1px solid $grey;
    border-radius: 10px;
    transition: 0.5s;
    &:hover {
      border: 1px solid $green;
      background-color: transparent;
    }
  }
  .ant-input-group-wrapper {
    border-radius: 50px;
    border: 1px solid $grey;
    padding: 10px;
    transition: 0.5s;
    &:focus-within,
    &:hover {
      border: 1px solid $green;
    }
  }
  .ant-input {
    color: $dark-grey;
    border: 0;
    &:focus {
      box-shadow: none;
    }
  }
  .ant-btn {
    border: 0;
    color: $green;
  }
  .anticon-search > svg {
    width: 1.5em;
    height: 1.5em;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .ant-descriptions-bordered .ant-descriptions-view {
    border: 0;
  }
  .ant-descriptions-row {
    border: 0;
  }
  .ant-descriptions-item-label {
    border: 0;
    background-color: transparent;
    font-weight: 600;
  }
  .ant-descriptions-title {
    color: $green;
    font-size: 1.3em;
  }
  .ant-table-thead > tr > th {
    font-weight: 600;
    background-color: transparent;
    border-bottom: 0;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: $green;
  }
  .icon-green {
    color: $green;
    margin-right: 10px;
  }
  .icon-red {
    color: rgb(240, 102, 102);
    margin-right: 10px;
  }
  .ant-spin {
    display: block;
    margin: 0 auto;
  }
  .ant-spin-dot-item {
    background-color: $green;
  }
  .ant-input-group-addon {
    background-color: $white;
  }
  .ant-btn.ant-btn-icon-only.ant-input-search-button {
    box-shadow: none;
    border-radius: 50%;
    background-color: $white;
    color: $green;
  }
  @media only screen and (max-width: 600px) {
    .verify-document-container {
      width: auto;
    }
    .ant-descriptions-row {
      display: flex;
      flex-direction: column;
    }
  }
}
.documentDetails {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  .Set {
    padding: 0.5rem;
    min-width: 0;
    width: 150px;
    box-sizing: border-box;
  }
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: $green;
}

// .ant-pagination-item-active a {
//   color: $white;
//   &:hover {
//     color: $white;
//   }
// }

// .ant-pagination-item-active {
//   border: 0;
//   background-color: $green;
//   border-radius: 50%;
// }
// .ant-pagination-prev .ant-pagination-item-link,
// .ant-pagination-next .ant-pagination-item-link {
//   border-radius: 50%;
// }
