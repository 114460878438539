@import '../../../styles/theme.scss';

#SideMenu {
  background-color: $dark-grey;
  .logo {
    padding: 1rem;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background-color: $green;
  }
  .ant-menu.ant-menu-dark,
  .ant-layout-sider-trigger {
    background-color: $dark-grey;
  }
}
