@import './theme.scss';

.upload {
  display: inline-block;
  cursor: pointer;
  position: relative;
  text-align: center;
  border-radius: 2px;
  background-color: $white;
  border: 1px dashed #d9d9d9;
  transition: border-color 0.3s;

  &--medium {
    width: 500px;
    margin: 25px 0;
    padding: 15px 0;
  }

  &--small {
    width: 100%;
    margin: 10px 0;
    padding: 10px 0;
  }

  &-icon {
    color: $green;
    &--medium {
      font-size: 48px;
    }
    &--small {
      margin: 0 10px;
      font-size: 18px;
    }
  }

  &:hover {
    border: 1px dashed $green;
  }
  .ant-upload {
    width: 100%;
  }
}
