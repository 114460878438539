@import '../../../styles/theme.scss';

.Breadcrumb {
  padding: 1rem;
  margin-top: 1rem;
  .ant-breadcrumb {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-breadcrumb a:hover {
    color: $green;
  }
}
