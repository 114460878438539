.userData {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 0.2fr 1fr 0.2fr 1fr ;
  padding: 0 1rem;
  
  h2 {
    grid-column: 1/4;
    grid-row: 1/2;
    padding-left: 1rem;
  }
  p {
    font-weight: bold;
  }
}

.avatar {
  grid-column: 1/2;
  grid-row: 2/3;
}

.dataContainer {
  grid-column: 2/4;
  grid-row: 2/3;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-size: medium;

  p {
    grid-column: 1/3;
  }

  small {
    grid-column: 3/5;
  }
}

.divider {
  grid-column: 1/6;
  grid-row: 3/4;
}

.positionData {
  grid-column: 1/6;
  grid-row: 4/5;
  display: grid;
  width: 80%;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5rem;
}
