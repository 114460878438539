.collapsableSection {
  padding: 0 1rem;
}

.controls {
  display: flex;
  justify-content: space-between;
  h2 {
    margin: 0;
  }
}

.isClosed {
  display: none;
}