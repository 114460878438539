@import '../../../styles/theme.scss';

#MainLayout {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .ant-layout-content {
    padding: 1rem;
    margin: 0;
  }
  .header {
    .userAvatar {
      color: white;
    }
  }
  .version {
    padding: 0.5rem;
    bottom: 0;
    right: 0;
    position: absolute;
  }
  .ant-layout-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
  }
}
.ant-layout-footer {
  background-color: $light-grey;
  padding: 29px;
}
