@import '../../../styles/theme.scss';

#PaymentForm {
  .footer {
    text-align: right;
    .ant-btn {
      margin-left: 0.5rem;
    }
  }
}
