.paymentConfig {
  display: grid;
  width: 80%;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
}

.collapse {
  padding-left: 10px;
}

.paymentService {
  grid-column: 1/4;
}
