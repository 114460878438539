.Set {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  .label {
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .value {
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .status-skeleton h3 {
    margin: 0;
    margin-top: 5px;
  }
}
