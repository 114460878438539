@import './theme.scss';

.ant-input:hover,
.ant-input:focus,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-checkbox-inner:hover,
.ant-select-selector:hover,
.ant-input-number input:hover,
.ant-select-selector:focus-within,
.ant-form-item-control-input-content input:hover {
  border-color: $green;
  box-shadow: none;
}

.ant-form-item {
  margin-bottom: 0.75rem;
  transition: all 2s ease;

  &:last-child {
    margin-bottom: 0;
  }

  &.no-help {
    .ant-form-item-explain {
      display: none;
    }

    .ant-form-item-control-input {
      margin-bottom: 0;
    }
  }
}

.ant-modal-body {
  max-height: 600px;
  overflow-y: auto;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-input:hover + .ant-checkbox-inner {
  border-color: $green;
}
.ant-checkbox.ant-checkbox-checked:hover {
  border-color: $green !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $green;
  border-color: $green;
}
