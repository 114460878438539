@import '../../../styles/theme.scss';

@import 'include-media';

#CustomHeader {
  a {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  .header {
    background-color: $light-grey;
    height: auto;
    padding: 20px;
    -webkit-box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.75);
    position: relative;
    z-index: 10;
    .flag-icon {
      width: 30px;
      cursor: pointer;
      margin: 0 5px;
      &:focus {
        border: 2px solid grey;
        outline-color: black;
      }
      &:last-child {
        margin-right: 10px;
      }
    }
    .userAvatar {
      margin-right: 1rem;
      padding: 0 5px;
      > div {
        margin-right: 0.5rem;
        height: 36px;
        display: flex;
        align-items: center;
      }
      border-top-left-radius: 10px;
      border-top-right-radius: 50px;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      &:hover {
        // background-color: $green;
        // color: $white;
        -webkit-box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
        cursor: pointer;
      }
    }
    .login-header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &-buttons {
        padding: 12px 0;
        @include media('<600px') {
          flex-direction: row;
          justify-content: center;
        }
      }
    }
    .ant-btn {
      margin-right: 0.5rem;
    }
    .button-colored {
      background-color: $green;
      border-color: $green;
      border-top-left-radius: 10px;
      border-top-right-radius: 50px;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      color: $white;
      padding: 6px 30px;
      font-weight: 500;
      height: auto;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      -webkit-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.75);
      &:hover {
        background-color: $green;
        color: $white;
        -webkit-box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
        border: 1px solid transparent;
        cursor: pointer;
      }
    }
  }
  .text-grey {
    color: $dark-grey;
  }
  .border {
    border: 2px solid red;
    outline: black;
  }
  @media only screen and (max-width: 600px) {
    .sm-flex-column {
      flex-direction: column;
    }
  }
}
