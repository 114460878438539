@import '../../../styles/theme.scss';

.block-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid $grey;
  &-title {
    margin: 0;
  }
}
