@import './theme.scss';
@import './breakpoints.scss';

@import 'include-media';

@mixin auth-typography {
  h1.auth-heading {
    font-size: clamp(20px, calc(1.25rem + ((1vw - 6.4px) * 3.3333)), 32px);
    line-height: clamp(32px, calc(2rem + ((1vw - 6.4px) * 2.2222)), 40px);
    margin-bottom: clamp(4px, calc(0.25rem + ((1vw - 6.4px) * 2.2222)), 12px);
    font-weight: 500;
  }

  // marketing heading
  h2.marketing-heading {
    font-weight: 600;
    font-size: clamp(16px, calc(1rem + ((1vw - 6.4px) * 1.1142)), 20px);
    line-height: clamp(20px, calc(1.25rem + ((1vw - 6.4px) * 3.3426)), 32px);
    color: rgba(30, 39, 50, 0.6);
  }

  .caption {
    display: inline-block;
    font-size: clamp(12px, calc(0.75rem + ((1vw - 6.4px) * 1.1111)), 16px);
    line-height: clamp(16px, calc(1rem + ((1vw - 6.4px) * 2.2222)), 24px);
    font-weight: 400;
    margin: 0;

    &__light-grey {
      color: $light-grey-text;
    }

    &-hello {
      margin-bottom: clamp(8px, calc(0.5rem + ((1vw - 6.4px) * 2.2222)), 16px);
    }

    &-secondary {
      margin-bottom: clamp(20px, calc(1.25rem + ((1vw - 6.4px) * 6.25)), 40px);
    }

    &__dark-grey {
      color: $dark-grey-text;
    }

    &-500 {
      font-weight: 500;
    }
  }
}

@mixin auth-tabs {
  // tab section
  .ant-tabs {
    overflow: visible !important;

    &-nav {
      margin-bottom: clamp(
        20px,
        calc(1.25rem + ((1vw - 6.4px) * 5.5556)),
        40px
      );
      padding-top: 4px;
    }

    &-nav-list {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }

    &-tab {
      height: 40px;
      display: flex;
      justify-content: center;
      width: 50%;
      margin: 0 !important;
      padding: 8px 0;
      font-size: 16px;
      font-weight: 500;
      transition: all 0.5s;
      box-sizing: border-box;
      border-bottom: 4px solid transparent;

      &:hover {
        background: rgba(30, 39, 50, 0.04);
        border-radius: 8px 8px 0px 0px;
        border-bottom: 4px solid rgba(30, 39, 50, 0.08);
      }

      &-btn {
        color: black !important;
        text-shadow: none !important;
      }

      // border: 1px solid black;
    }

    &-ink-bar {
      background-color: $green;
      width: 50% !important;
      height: 4px;
    }

    &-nav-operations {
      display: none;
    }
  }

  .active-tab {
    &--left {
      left: 0 !important;
    }

    &--right {
      left: 50% !important;
    }
  }
}
// form styles
@mixin custom-form {
  .card {
    box-shadow: 0px 0px 0px 1px rgba(30, 39, 50, 0.12),
      0px 0px 8px rgba(30, 39, 50, 0.04), 0px 4px 6px rgba(30, 39, 50, 0.04),
      0px 6px 4px rgba(30, 39, 50, 0.04), 0px 0px 0px 8px rgba(30, 39, 50, 0.04),
      inset 0px 0px 0px 1px rgba(30, 39, 50, 0.12);
    border-radius: 20px;
    padding: 0 clamp(16px, calc(1rem + ((1vw - 6.4px) * 6.6667)), 40px);
    padding-top: clamp(12px, calc(0.75rem + ((1vw - 6.4px) * 2.2222)), 20px);
    padding-bottom: clamp(20px, calc(1.25rem + ((1vw - 6.4px) * 5.5556)), 40px);
    margin-bottom: 40px; //? dynamic to a smaller one?
    width: 100%;
    background: white;

    &-heading {
      margin-bottom: clamp(
        9.5px,
        calc(0.59375rem + ((1vw - 6.4px) * 1.8056)),
        16px
      );
    }

    &-body {
      padding-top: clamp(16px, calc(1rem + ((1vw - 6.4px) * 1.1111)), 20px);
    }
    .ant-form-item {
      &-label {
        font-size: 12px;
        line-height: 16px;
        padding: 0;
        padding-left: 12px;
      }
    }
  }
}

@mixin custom-checkbox {
  // ! fix line height at small devices
  .checkbox {
    &.ant-form-item-has-error {
      .ant-checkbox {
        &-inner {
          border: 2px solid rgba(255, 59, 48, 0.8);
        }
        &:hover {
          .ant-checkbox-inner {
            border-color: #ff3b30;
          }
          // background-color: black;

          &::before {
            opacity: 1;
            box-shadow: 0px 0px 0px 11px rgba(255, 59, 48, 0.08);
          }
        }

        &::before {
          box-shadow: 0px 0px 0px 0px rgba(255, 59, 48, 0.08);
        }
      }
    }
    &.ant-form-item {
      margin-bottom: 0;
    }
    .ant {
      &-form-item {
        &-control-input {
          margin: 8px 12px;
          &-content {
            // height: 24px;
            line-height: 1;
          }
        }
        &-explain {
          display: none;
        }
        &-required {
          & > span:last-child {
            @include required-mark-after;
          }
        }
      }
      &-checkbox {
        position: relative;
        margin: 3px;
        top: 0;
        &:hover::before {
          opacity: 1;
          box-shadow: 0px 0px 0px 11px rgba(30, 39, 50, 0.08);
        }

        &::before {
          box-shadow: 0px 0px 0px 0px rgba(30, 39, 50, 0.08);
          pointer-events: none;
          content: '';
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: transparent;
          display: inline-block;
          position: absolute;
          opacity: 0;
          transition: all 0.5s;
        }
        &-input {
          width: 18px;
          height: 18px;
        }
        &-inner {
          border: 2px solid $dark-grey-text;
          border-radius: 10%;
          width: 18px;
          height: 18px;
        }
        &-wrapper {
          display: inline-flex;
          // align-items: baseline;
          align-items: flex-start;
          &-checked {
            .ant-checkbox-inner {
              border-color: rgba(0, 122, 255, 0.8);
              background-color: rgba(0, 122, 255, 0.8);
            }
          }
          & > span:last-child {
            padding-left: 12px;
            padding-right: 0;
            @include media('>400px', '<600px') {
              margin-top: 4px;
            }
          }
        }
        &-checked {
          &::after {
            border-color: rgba(0, 122, 255, 0.8);
            pointer-events: none;
          }
        }
      }
    }
  }
}

@mixin custom-input {
  .ant-form-item {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0 !important;
    }

    &-label {
      font-size: 12px;
      line-height: 16px;
      padding: 0;
    }

    &-control-input {
      margin: clamp(8px, calc(0.5rem + ((1vw - 6.4px) * 1.1111)), 12px);
      min-height: auto;
    }

    &-explain {
      font-size: 12px;
      line-height: 16px;
      padding: 0 12px;
      min-height: 16px;
      &-connected {
        min-height: 16px;
        opacity: 1;
      }
      &-error {
        color: $red;
      }
    }

    &-required {
      @include required-mark-after;
    }

    &.with-help {
      margin-bottom: 20px;
    }

    &.no-help {
      .ant-form-item-explain {
        display: none;
      }

      .ant-form-item-control-input {
        // margin-top: clamp(8px, calc(0.5rem + ((1vw - 3.2px) * 0.5882)), 12px);
        margin-bottom: 0;
      }
    }
  }

  // regualr input
  .ant-input {
    @include input-item;
    @include input-item-shadows;

    &:hover {
      @include input-item-hovered;
    }

    &:focus {
      @include input-item-focused;
    }
  }

  // password item has a specific structure in the Antd
  .ant-input-affix-wrapper {
    &.password-input {
      @include input-item;
      @include input-item-shadows;

      &:hover {
        @include input-item-hovered;
      }

      .ant-input {
        box-shadow: none;
        padding: 0;
      }
    }

    &-focused.ant-input-password.password-input {
      @include input-item-focused;
    }
  }

  .ant-form-item-has-error {
    .ant-input {
      @include input-item-error;

      &:hover {
        @include input-item-error-hovered;
      }

      &:focus {
        @include input-item-error-focused;
      }
    }

    // password error
    .ant-input-affix-wrapper {
      &.password-input {
        @include input-item-error;

        &:hover {
          @include input-item-error-hovered;
        }

        .ant-input {
          box-shadow: none;
          padding: 0;
        }
      }

      &-focused.ant-input-password.password-input {
        @include input-item-error-focused;
      }
    }
  }
}

@mixin custom-buttons {
  .ant-btn {
    border: 0;
    box-sizing: border-box;
    // background-color: $white;
  }

  .ant-btn.button-round {
    height: auto;
    text-shadow: none;
    border-radius: 24px;
    padding: 12px 20px;
    font-weight: 500;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 0px 0px 1px rgba(30, 39, 50, 0.12),
      0px 0px 0px rgba(30, 39, 50, 0.04), 0px 0px 0px rgba(30, 39, 50, 0.04),
      0px 0px 0px rgba(30, 39, 50, 0.04), 0px 0px 0px 0px rgba(30, 39, 50, 0.04),
      inset 0px 0px 0px 1px rgba(30, 39, 50, 0.12);

    &:hover {
      box-shadow: 0px 0px 0px 1px rgba(30, 39, 50, 0.12),
        0px 0px 8px rgba(30, 39, 50, 0.04), 0px 4px 6px rgba(30, 39, 50, 0.04),
        0px 6px 4px rgba(30, 39, 50, 0.04),
        0px 0px 0px 8px rgba(30, 39, 50, 0.04),
        inset 0px 0px 0px 1px rgba(30, 39, 50, 0.12);
    }

    &__secondary {
      background: transparent;
      color: rgba(30, 39, 50, 0.8);
      &:hover {
        color: #1e2732;
      }
    }
    &__primary {
      background-color: $green;
      border-color: $green;
      color: $white;
    }
  }

  .ant-btn.button-round:disabled {
    background-color: #cccccc;
  }
}

@mixin auth-structure {
  .divider {
    border-top: 1px solid rgba(30, 39, 50, 0.12);
    margin: 0;
  }

  .container {
    position: relative;
    min-height: 100vh;
    padding-top: 64px;
  }

  .body {
    width: 70%;
    background-color: #fafbfb;
  }

  .main {
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 40px clamp(99px, calc(6.1875rem + ((1vw - 12.8px) * 22.5)), 243px);

    &-container {
      width: 100%;
    }

    &-email,
    &-forgot-password {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 20px 40px;
  }

  .marketing {
    display: flex;
    justify-content: center;
    padding: clamp(20px, calc(1.25rem + ((1vw - 9.6px) * 6.25)), 40px);
    background-color: rgba(52, 199, 89, 0.08);
    width: 30% !important;
    max-width: none !important;
    min-width: auto !important;
    flex: none !important;
    box-shadow: -0.5px 0px 0px rgba(30, 39, 50, 0.12),
      -4px 0px 0px rgba(30, 39, 50, 0.04),
      inset 0.5px 0px 0px rgba(30, 39, 50, 0.12);

    &-list {
      margin: 0;
      padding-left: 20px;
    }
  }

  @include media('>s', '<=m') {
    .main {
      padding: clamp(20px, calc(1.25rem + ((1vw - 9.6px) * 6.25)), 40px)
        clamp(66px, calc(4.125rem + ((1vw - 9.6px) * 10.3125)), 99px);
    }

    .marketing {
      width: 40% !important;
    }

    .body {
      width: 60%;
    }
  }

  @include media('>xs', '<=s') {
    .main {
      padding: 20px 20px;
    }
    .marketing {
      width: 50% !important;
    }

    .body {
      width: 50%;
    }
  }

  @include media('<=xs') {
    .main {
      padding: 20px 20px;
    }
    .marketing {
      display: none;
    }

    .body {
      width: 100%;
    }

    .ant-btn.button-round {
      width: 100%;
    }
  }
}

@mixin steps {
  .ant-steps {
    margin: 20px 0;
    &-item {
      height: 48px;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        width: 48px;
        height: 48px;
        border-radius: 24px;
        background: transparent;
        transition: all ease 1s;
        box-shadow: 0px 0px 0px 1px rgba(30, 39, 50, 0.24),
          0px 0px 8px rgba(30, 39, 50, 0.04), 0px 4px 6px rgba(30, 39, 50, 0.04),
          0px 6px 4px rgba(30, 39, 50, 0.04),
          0px 0px 0px 8px rgba(30, 39, 50, 0.04),
          inset 0px 0px 0px 1px rgba(30, 39, 50, 0.24);
      }
      &-content {
        min-height: auto;
        transition: all ease 1s;
        padding: clamp(8px, calc(0.5rem + ((1vw - 6.4px) * 1.25)), 12px);
      }
      &-title {
        font-size: 16px;
        line-height: 24px;
      }
      &-wait {
        .ant-steps-item-icon {
          box-shadow: 0px 0px 0px 1px rgba(30, 39, 50, 0.12),
            0px 0px 0px rgba(30, 39, 50, 0.04),
            0px 4px 0px rgba(30, 39, 50, 0.04),
            0px 6px 0px rgba(30, 39, 50, 0.04),
            0px 0px 0px 0px rgba(30, 39, 50, 0.04),
            inset 0px 0px 0px 1px rgba(30, 39, 50, 0.12);
        }
      }
      &-active {
        .ant-steps-item-icon {
          background: white;
        }
        .ant-steps-item-content {
          background: rgba(30, 39, 50, 0.04);
        }
      }
      &-finish {
        .ant-steps-item-icon {
          box-shadow: 0px 0px 0px 1px rgba(30, 39, 50, 0.12),
            0px 0px 0px rgba(30, 39, 50, 0.04),
            0px 4px 0px rgba(30, 39, 50, 0.04),
            0px 6px 0px rgba(30, 39, 50, 0.04),
            0px 0px 0px 0px rgba(30, 39, 50, 0.04),
            inset 0px 0px 0px 1px rgba(30, 39, 50, 0.12);
        }
        .ant-steps-icon {
          // top: 1px;
        }
      }
      &-tail {
        &::after {
          // height: 200%;
          // top: -10px;
          background-color: white;
        }
      }
    }
    &-icon {
      color: black;
      // left: 0.5px;
      // top: -0.5px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
