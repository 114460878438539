@import './theme.scss';

.ant-steps {
  .ant-steps-item {
    &-tail::after,
    .ant-steps-icon-dot {
      background-color: $green !important;
    }
  }

  &.ant-steps-vertical .ant-steps-item-content {
    min-height: auto;
  }
}
