@import './theme.scss';

.ant-btn-primary {
  text-shadow: none;
  box-shadow: none;
}

/*Round buttons with only icon inside*/

.ant-btn-primary.ant-btn-circle.ant-btn-icon-only {
  background-color: $green;
  color: $white;
  // width: 50px;
  // height: 50px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.75);
  margin-left: 10px;

  &:hover {
    -webkit-box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
  }

  &:disabled {
    background-color: $grey;
  }
}

.ant-btn-dangerous {
  background-color: $red !important;
}

.ant-btn {
  // color: $green;
  // background-color: $white;
  &.button-round {
    // color: $white;
    line-height: 1;
    border-radius: 50px;
    padding: 6px 30px;
    font-weight: 500;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -webkit-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.75);
    &:hover {
      -webkit-box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
    }
  }

  &.ant-input-search-button {
    border: 0;
    color: $green;
    background-color: $white;
  }

  &.button-green {
    border: 0;
    color: $green;
    background-color: $white;
  }

  &.button-round.button-white {
    background-color: $white;
    border-color: $grey;
    color: $dark-grey;
  }

  &.button-round.button-green {
    background-color: $green;
    border-color: $green;
    color: $white;
  }

  &.button-round:disabled {
    background-color: #cccccc;
  }
}
