@import '../../../styles/theme.scss';

#PaymentsPage {
  padding: 1rem;
  background-color: $white;
  .title {
    color: $green;
    padding-top: 10px;
  }
  .payments-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      margin: 0 10px;
    }
  }
  .ant-upload {
    display: block;
    height: auto;
  }
  .upload-button span.ant-upload {
    display: flex;
    justify-content: center;
  }
  .button-center {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  /* Inputs */
  .ant-input-group-wrapper,
  .documentQuery .ant-input-affix-wrapper {
    border-radius: 50px;
    border: 1px solid $grey;
    transition: 0.5s;
    &:focus-within,
    &:hover {
      border: 1px solid $green;
      box-shadow: none;
    }
  }
  .ant-input {
    color: $dark-grey;
    background-color: $white;
    border: 0;
    width: 100%;
    margin-top: 10px;
    margin-left: 5px;
    &:focus {
      box-shadow: none;
    }
  }
  input:-internal-autofill-selected {
    background-color: $white;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  .ant-input-group-addon {
    background-color: transparent;
    padding: 10px;
  }
  .ant-input-search-button {
    border-radius: 50%;
    padding: 5px;
  }
  .anticon-search {
    display: flex;
    align-items: center;
    > svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
  .documentSearch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 80px;
    > .DocumentUploadContainer {
      flex-grow: 10;
    }
    > .button-container {
      flex-grow: 1;
    }
  }
  /* Spinner */
  .documentUploadSpinner {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mt-30 {
    margin-top: 30px;
  }
}
