@import '~@fontsource/poppins/index.css';
@import '~@fontsource/poppins/500.css';
@import '~@fontsource/poppins/600.css';

@import '../../../styles/auth.scss';

#RtpConfirmationPage,
.modal {
  font-family: 'Poppins', sans-serif;

  @include auth-tabs;
  @include auth-structure;
  @include auth-typography;
  @include custom-form;
  @include custom-input;
  @include custom-checkbox;
  @include custom-buttons;
  @include steps;

  .card {
    // max-width: 60%;
    // margin: 0 auto;
  }
}

.ant-modal-body {
  max-height: 550px;
  overflow: auto;

  & table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
}
