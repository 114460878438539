@import './theme.scss';
@import 'include-media';

.card-table {
  table {
    border-collapse: separate;
    border-spacing: 0 2em;
  }

  .ant-table-cell {
    background-color: transparent;
    border: none;
    padding: 16px 5px;
  }

  th.ant-table-cell {
    padding-top: 0;
    padding-bottom: 0;

    &::before {
      border: 0;
      background-color: transparent;
    }

    &.ant-table-column-has-sorters:hover {
      background: transparent;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: $white;
  }

  &-row {
    &--expandable {
      cursor: pointer;
    }

    transition: 0.3s;

    &:hover {
      -webkit-box-shadow: 0px 10px 15px 0px rgba(173, 173, 173, 0.5);
      -moz-box-shadow: 0px 10px 15px 0px rgba(173, 173, 173, 0.5);
      box-shadow: 0px 10px 15px 0px rgba(173, 173, 173, 0.5);
    }

    & td {
      border-bottom: 1px solid $grey !important;
      border-top: 1px solid $grey !important;

      &:first-child {
        @include media('<400px') {
          padding-left: 5px;
        }
        padding-left: 10px;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        border-left: 1px solid $grey !important;
      }

      &:last-child {
        @include media('<400px') {
          // padding-right: 0px;
        }
        // float: right;
        // display: flex;
        // justify-content: en;
        padding-right: 10px;
        position: relative;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        border-right: 1px solid $grey !important;
      }
    }
  }
}
